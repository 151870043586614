import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {AuthProvider} from './hooks/AuthProvider';
import ProtectedRoute from './routes/ProtectedRoute';

export const UserContext = React.createContext();

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

//Layouts
const FrontLayout = React.lazy(() => import("./layouts/front/Layout"));
const AdminLayout = React.lazy(() => import("./layouts/admin/Layout"));
const ActiveSession = React.lazy(() => import("./views/ActiveSession/ActiveSession"));


function App() {

  return (
    <BrowserRouter>
    <AuthProvider>
      
          <Suspense fallback={loading()}>
            <Routes>
              <Route exact path="/admin/sessions/active" element={<ActiveSession />} />
              <Route exact path="/admin/*" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>} />
              <Route path="/*" element={<FrontLayout />} />
            </Routes>
          </Suspense>
      
    </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
