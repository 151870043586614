import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/AuthProvider';
import routes from './adminRoutes';

const ADMIN_PATH = '/admin';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, sessionUser } = useAuth();
    const location = useLocation();

    // Check for authentication
    if (!isAuthenticated) {
        let notifications = [
            {
                type: "warning",
                message: "You have tried to access a page which requires you to log in.",
                autoClose: 8000 
            }
        ];
        return <Navigate to="/" replace state={{notifications: notifications}} />;
    }

    const matchedRoute = routes.find(({ path }) => path === location.pathname.replace(ADMIN_PATH, ''));

    if (Object.keys(sessionUser).length !== 0 && matchedRoute) {
        const { permissions } = matchedRoute;
        let hasPermission = false;

        // Check if organization is active
        if (!sessionUser.organization.subscriptionData.isActive && matchedRoute.name !== "Settings") {
            let notifications = [
                {
                    type: "warning",
                    message: "You have tried to access a page which requires you to have an active account. Please check your subscription details.",
                    autoClose: 8000 
                }
            ];
            return <Navigate to="/admin/settings" replace state={{notifications: notifications}} />;
        }

        // Check if route needs permission
        if (permissions && permissions.length > 0) {
            hasPermission = permissions.some(routePermission =>
                sessionUser.role.permissions.some(permission => permission.name === routePermission)
            );
        } else {
            hasPermission = true; // Route doesn't require any specific permission
        }

        if (!hasPermission) {
            let notifications = [
                {
                    type: "warning",
                    message: "You have tried to access a page which you don't have permission to access.",
                    autoClose: 8000 
                }
            ];
            const redirectPath = sessionUser.role.code === "patient" ? '/admin/homework/list' : '/admin/sessions/today';
            return <Navigate to={redirectPath} replace state={{notifications: notifications}} />;
        }
    }

    return children;
};

export default ProtectedRoute;
