import React from "react";

const Users = React.lazy(() => import("../views/Admin/Users/Users"));
const AllUsers = React.lazy(() => import("../views/Admin/Users/AllUsers"));
const InactiveUsers = React.lazy(() => import("../views/Admin/Users/InactiveUsers"));
const Patients = React.lazy(() => import("../views/Admin/Patients/Patients"));
const AllPatients = React.lazy(() => import("../views/Admin/Patients/AllPatients"));
const UnassignedPatients = React.lazy(() => import("../views/Admin/Patients/UnassignedPatients"));
const TodayPatientSessions = React.lazy(() => import("../views/Admin/PatientSessions/TodayPatientSessions"));
const WeekPatientSessions = React.lazy(() => import("../views/Admin/PatientSessions/WeekPatientSessions"));
const SinglePatientSessions = React.lazy(() => import("../views/Admin/PatientSessions/SinglePatientSessions"));
const ByPatientSessions = React.lazy(() => import("../views/Admin/PatientSessions/ByPatientSessions"));
const Pages = React.lazy(() => import("../views/Admin/Pages/Pages"));
const AccessLog = React.lazy(() => import("../views/Admin/AccessLog/AccessLog"));
const Notes = React.lazy(() => import("../views/Admin/Notes/Notes"));
const UserTemplates = React.lazy(() => import("../views/Admin/PatientSessions/Templates/UserTemplates"));
const UserTemplateSessions = React.lazy(() => import("../views/Admin/PatientSessions/Templates/UserTemplateSessions"));
const UserTemplatePages = React.lazy(() => import("../views/Admin/PatientSessions/Templates/UserTemplatePages"));
const Settings = React.lazy(() => import("../views/Admin/Settings/Settings"));
const CoreTemplates = React.lazy(() => import("../views/Admin/Core/Sessions/CoreTemplates"));
const TemplateSessions = React.lazy(() => import("../views/Admin/Core/TemplateSessions/TemplateSessions"));
const TemplatePages = React.lazy(() => import("../views/Admin/Core/TemplatePages/TemplatePages"));
const Logs = React.lazy(() => import("../views/Admin/Core/Logs/Logs"));
const Contacts = React.lazy(() => import("../views/Admin/Core/Contacts/Contacts"));
const TemplateHomework = React.lazy(() => import("../views/Admin/Homework/TemplateHomework"));
const PatientHomework = React.lazy(() => import("../views/Admin/Homework/PatientHomework"));
const ListHomework = React.lazy(() => import("../views/Admin/Homework/ListHomework"));
const ViewHomework = React.lazy(() => import("../views/Admin/Homework/ViewHomework"));
const CoreListHomework = React.lazy(() => import("../views/Admin/Core/Homework/CoreListHomework"));
const SupervisorPatients = React.lazy(() => import("../views/Admin/Supervisor/SupervisorPatients"));
const SupervisorSessions = React.lazy(() => import("../views/Admin/Supervisor/SupervisorSessions"));
const SupervisorHomework = React.lazy(() => import("../views/Admin/Supervisor/SupervisorHomework"));
const SupervisorCaseNotes = React.lazy(() => import("../views/Admin/Supervisor/SupervisorCaseNotes"));
const CaseNotes = React.lazy(() => import("../views/Admin/CaseNotes/CaseNotes"));
const AllCaseNotes = React.lazy(() => import("../views/Admin/CaseNotes/AllCaseNotes"));
const TemplateCaseNotes = React.lazy(() => import("../views/Admin/CaseNotes/TemplateCaseNotes"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = [
  { path: "/users/list", exact: true, name: "Users", element: Users, level: 2, permissions: ["canHaveSubUsers"] },
  { path: "/users/all", exact: true, name: "All Users", element: AllUsers, level: 3, permissions: ["manageUserAccounts"] },
  { path: "/users/inactive", exact: true, name: "Inactive Users", element: InactiveUsers, level: 2, permissions: ["canHaveSubUsers"] },
  { path: "/homework/templates", exact: true, name: "Homework Templates", element: TemplateHomework, level: 3, permissions: ["canHaveHomeworkTemplates"] },
  { path: "/homework/patient", exact: true, name: "Patient Homework", element: PatientHomework, level: 3, permissions: ["canHavePatients"] },
  { path: "/homework/list", exact: true, name: "Homework", element: ListHomework, level: 4, permissions: [] },
  { path: "/homework/view", exact: true, name: "View Homework", element: ViewHomework, level: 4, permissions: ["canHavePatients", "isPatient"] },
  { path: "/patients/list", exact: true, name: "Patients", element: Patients, level: 3, permissions: ["canHavePatients"] },
  { path: "/patients/all", exact: true, name: "All Patients", element: AllPatients, level: 3, permissions: ["manageAllPatients"] },
  { path: "/patients/unassigned", exact: true, name: "Unassigned Patients", element: UnassignedPatients, level: 3, permissions: ["manageAllPatients"] },
  { path: "/sessions/today", exact: true, name: "Today", element: TodayPatientSessions, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/week", exact: true, name: "Week", element: WeekPatientSessions, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/bypatient", exact: true, name: "By Patient", element: ByPatientSessions, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/pages", exact: true, name: "Pages", element: Pages, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/patient", exact: true, name: "Patient Sessions", element: SinglePatientSessions, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/log", exact: true, name: "Session Access Log", element: AccessLog, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/notes", exact: true, name: "Session Notes", element: Notes, level: 3, permissions: ["canHavePatients"] },
  { path: "/sessions/outlines", exact: true, name: "Session Outlines", element: UserTemplates, permissions: ["canHaveSessionTemplates"] },
  { path: "/sessions/outlines/modules", exact: true, name: "Template Modules", element: UserTemplateSessions, permissions: ["canHaveSessionTemplates"] },
  { path: "/sessions/outlines/modules/pages", exact: true, name: "Template Pages", element: UserTemplatePages, permissions: ["canHaveSessionTemplates"] },
  { path: "/settings", exact: true, name: "Settings", element: Settings, permissions: []},
  { path: "/core/sessions", exact: true, name: "Session Outlines", element: CoreTemplates, level: 1, permissions: ["coreTemplateAccess"] },
  { path: "/core/outlines/sessions", exact: true, name: "Template Modules", element: TemplateSessions, level: 1, permissions: ["coreTemplateAccess"] },
  { path: "/core/outlines/sessions/pages", exact: true, name: "Pages", element: TemplatePages, level: 1, permissions: ["coreTemplateAccess"] },
  { path: "/core/homework", exact: true, name: "Homework Templates", element: CoreListHomework, level: 1, permissions: ["coreTemplateAccess"] },
  { path: "/core/logs", exact: true, name: "Logs", element: Logs, level: 1, permissions: ["coreTemplateAccess"] },
  { path: "/core/contacts", exact: true, name: "Contacts", element: Contacts, permissions: ["coreTemplateAccess"] },
  { path: "/supervisor/patients/list", exact: true, name: "Supervised Patients", element: SupervisorPatients, level: 2, permissions: ["canHaveSubUsers"] },
  { path: "/supervisor/sessions/list", exact: true, name: "Supervised Sessions", element: SupervisorSessions, level: 2, permissions: ["canHaveSubUsers"] },
  { path: "/supervisor/homework/list", exact: true, name: "Supervised Homework", element: SupervisorHomework, level: 2, permissions: ["canHaveSubUsers"] },
  { path: "/supervisor/case-notes/list", exact: true, name: "Supervised Case Notes", element: SupervisorCaseNotes, level: 2, permissions: ["canHaveSubUsers"] },
  { path: "/case-notes/list", exact: true, name: "Case Notes", element: CaseNotes, permissions: ["canHavePatients"] },
  { path: "/case-notes/all", exact: true, name: "All Case Notes", element: AllCaseNotes, permissions: ["viewAllCaseNotes"] },
  { path: "/case-notes/templates", exact: true, name: "Case Notes Templates", element: TemplateCaseNotes, permissions: ["canHavePatients"] },
];

export default routes;
