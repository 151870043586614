import React, { useState, useEffect, useMemo } from 'react'
import auth from '../services/authService';
import { useNavigate,} from 'react-router-dom';
import { toast } from 'react-toastify';

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [sessionUser, setSessionUser] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);

    useEffect(() => {
        checkAuth();
    }, []);

    const handleLogin = async (loginData) => {
        setLoading(true);

        const sessionCall = async () => {
            const sessionUserResponse = await auth.login(loginData.email, loginData.password);      
            return sessionUserResponse.data;
        };

        sessionCall()
            .then((loginResponse) => {
                setIsAuthenticated(true);
                setSessionUser(loginResponse);
                
                let path = "/admin/sessions/today";

                //change path for patients
                if(loginResponse.role.code === "patient") {
                    path = "/admin/homework/list";
                }                

                //change path if users don't have access to sessions
                const canHavePatientPermission = loginResponse.role.permissions.find(permission => permission.name === "canHavePatients");
                const canManageAllPatientsPermission = loginResponse.role.permissions.find(permission => permission.name === "manageAllPatients");

                if (canHavePatientPermission === undefined && canManageAllPatientsPermission) {
                    path = "/admin/patients/all";
                }

                //change path if account is not active
                if(!loginResponse.organization.subscriptionData.isActive) {
                    path = "/admin/settings";
                }
                
                
                //create welcome notification
                let notifications = [
                    {
                        type: "success",
                        message: `Welcome ${loginResponse.firstName} ${loginResponse.lastName}!`,
                        autoClose: 3000 
                    }
                ];

                //add any system notifications
                if (loginResponse.notifications) {
                    var len = loginResponse.notifications.length;
                    while (len--) {
                        let systemNotification = {
                            type: loginResponse.notifications[len].type,
                            message: loginResponse.notifications[len].message,
                            autoClose: false 
                        }

                        notifications.push(systemNotification);
                    }
                }

                navigate(path,{state:{notifications: notifications}});
            })
            .catch((error) => {
                toast.error(<span>{error.response.data.message}</span>);
            })
            .finally(() => setLoading(false));
        
    };

    const checkAuth = async () => {

        const checkCall = async () => {
            const checkResponse = await auth.check();      
            return checkResponse.data.user;   
        };

        checkCall()
            .then((checkResponse) => {
                setIsAuthenticated(true);
                setSessionUser(checkResponse);
            })
            .catch((error) => {
                setSessionUser({});
                setIsAuthenticated(false);
            })
            .finally(() => setLoadingInitial(false));

    }

    function updateSessionUser(sessionUserData) {
        setSessionUser(sessionUserData);
    }

    const handleLogout = async () => {
        
        const logoutCall = async () => {
            const logoutResponse = await auth.logout();         
            return logoutResponse.data;            
        };

        logoutCall()
            .then(() => {
                navigate("/")
            })
            .catch(() => {
                toast.error(<span>There was a problem logging you out.  Please close your browser to insure your session has ended.</span>)
            })    
            .finally(() => {
                setSessionUser({});
                setIsAuthenticated(false);
            })    
        
    };

    const memoedValue = useMemo(
        () => ({
            sessionUser,
            isAuthenticated,
            loading,
            onLogin: handleLogin,
            onLogout: handleLogout,
            checkAuth: checkAuth,
            updateSessionUser: updateSessionUser,
        }),
        [sessionUser, loading, isAuthenticated]
    );

    return (
        <AuthContext.Provider value={memoedValue}>
        {!loadingInitial && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};