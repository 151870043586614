import http from './httpService';

const apiEndpoint = '/auth';

async function login(email, password) {
  const response = await http.post(apiEndpoint, { email, password });
  return response;
}

function logout() {
  return http.post(apiEndpoint + '/logout');
}

function check() {
  return http.post(apiEndpoint + '/check');

}

async function authenticate() {
  try {
    const check = await http.post(apiEndpoint + '/check');
    if (check.data.user) {
      return check.data.user
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return false;
    }
  }

}
const authService = {login, logout, check, authenticate};
export default authService;